.container {
  p {
    margin-top: 1rem;
  }

  .reportGroupContainer {
    margin-top: 3rem;

    svg {
      color: #da0846;
    }
  }
}
