@use "sass:math";

.container {
  td {
    min-width: 15rem;
  }

  .backdrop {
    z-index: 99999;
  }

  button {
    margin-left: 0.25rem;
    svg {
      top: 0.5rem;
      position: relative;
    }
    background-color: white;
    &:hover {
      background-color: #f5f5f5 !important;
    }
  }

  .checkbox {
    padding: 0;
  }

  .countButton {
    margin: 0;
    margin-left: 0.5rem;
    padding: 0;
    svg {
      top: -0.1rem;
      position: relative;
    }
    &:hover {
      background-color: #f5f5f5 !important;
    }

    &:nth-of-type(1) {
      position: relative;
      top: -0.75rem;
    }

    &:nth-of-type(2) {
      position: relative;
      top: 0.7rem;
      left: -2rem;
    }
  }
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .paper {
    padding: 16px 32px 24px;
    box-shadow: 0px 3px 5px -1px rgb(0, 0, math.div(0, 20)),
      0px 5px 8px 0px rgb(0, 0, math.div(0, 14)), 0px 1px 14px 0px rgb(0, 0, math.div(0, 12));
    background-color: #fff;
    //max-width: 1000px;
    min-width: 600px;
    max-height: 90%;
    overflow-y: auto;
    line-height: 2;
  }

  strong {
    font-weight: bold;
  }

  span {
    margin-bottom: 0.25rem;
  }

  .formControl {
    margin-bottom: 1rem;
  }

  input,
  textarea {
    border: black 1px solid;
    width: 100%;
  }

  button {
    background-color: #636c93;
    color: white;
    margin-right: 1rem;
    margin-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    cursor: pointer;

    svg {
      top: 0.3rem;
      position: relative;
    }

    &:disabled {
      background-color: #636c93;
      color: white;
      opacity: 0.5;
    }
  }
}
