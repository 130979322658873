.container {
  div.enrolments {
    margin-top: 1rem;
    font-weight: 400;

    .accordion {
      margin-top: 1rem;
      margin-left: 1rem;
    }

    .accordionSummary {
      background-color: #f5f5f5;
      border-radius: 4px;
      padding: 0.25rem 1rem;
      cursor: pointer;

      h2 a {
        font-size: small;
      }
    }

    .accordionDetails {
      h3 {
        font-weight: bold;
        margin-top: 1rem;
        margin-bottom: 0.25rem;
      }
    }
  }
}

.notes {
  .criticalIcon {
    margin-bottom: -0.25rem;
    font-size: 1.25rem;
  }

  button {
    padding: 0;
    margin-left: 1rem;
  }

  .tableContainer {
    max-height: 15rem;

    .noteRow {
      cursor: pointer;
    }
  }
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .paper {
    padding: 0px 32px 24px;
    box-shadow: 0px 3px 5px -1px rgb(0, 0, 0/20), 0px 5px 8px 0px rgb(0, 0, 0/ 14),
      0px 1px 14px 0px rgb(0, 0, 0/ 12);
    background-color: #fff;
  }

  .noteTitle {
    font-weight: bold !important;
    max-width: 50rem;
  }

  .noteContent {
    margin-top: 1rem;
    max-width: 50rem;
  }
}
