@import 'variables';

.container {
  padding: 1rem 1rem 1rem 0;

  .changeTimeZoneButton {
    background-color: white;
    border: 1px solid $primary-blue;
    color: $primary-blue;
    border-radius: 10px;
    span {
      text-transform: uppercase !important;
    }
    &:hover {
      background-color: white !important;
      color: $primary-blue;
    }
  }

  span {
    text-transform: none;
  }

  .timezoneSelector {
    width: 20rem;
    margin-bottom: 1rem;
  }

  h2,
  p {
    margin-bottom: 1rem;
    strong {
      font-weight: bold;
    }
  }

  h2 {
    color: $primary-blue;
  }

  .rowHeaderCell {
    * {
      color: $primary-blue;
      font-weight: bold;
    }
  }

  strong {
    font-weight: 600;
  }

  .row {
    &:nth-child(odd) {
      background-color: rgb(249, 249, 249);

      .noWrapCell {
        white-space: nowrap;
      }
    }
  }

  .buttonLink {
    margin-right: 0.2rem;
    background-color: $primary-red;
    color: white;
  }
}
