.container {
  font-size: 14px;
  color: rgb(50, 50, 50);

  strong {
    font-weight: 600;
  }

  h1 {
    font-weight: 600;
    font-size: 16px;
  }

  h2 {
    color: #e43f;
    line-height: 36px;
    font-size: 12px;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  h3 {
    font-weight: 500;
  }

  i {
    font-style: italic;
    font-size: 0.8rem;
  }

  .scheduleContainer {
    margin-top: 1rem;
    padding: 0;
    background-color: whitesmoke;
    border-radius: 15px;

    div div {
      padding: 0.25rem 0.75rem 0.25rem 0.75rem;
    }

    .tutorialScheduleContainer {
      div {
        padding: 0;
      }
    }

    .timezoneButton {
      margin-left: 1rem;
      color: white;
      background-color: #da0846;
      padding: 0.25rem;
      border-radius: 5px;
      display: inline;
    }

    .timezoneSelector {
      max-width: 25rem;
      margin-top: 0.5rem;
      padding: 0;

      div {
        padding: 0;

        div div {
          background-color: white;
        }
      }
    }
  }
}
