.container {
  .backdrop {
    z-index: 99999 !important;
    color: white;
  }

  .openInNewIcon {
    position: relative;
    top: 0.5rem;
  }
}

.closeDialog {
  width: 25rem;
}
