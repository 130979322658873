.container {
  margin-top: 2rem;
  .select {
    max-width: 50rem;
  }

  span.tag {
    margin-right: 0.5rem;
    background: #da0846;
    color: white;
    border-radius: 5px;
    padding: 0.1rem 0.3rem;
  }

  svg {
    margin-bottom: -0.25rem;
    font-size: 1.25rem;
  }
}
