.container {
  .appbar {
    flex-flow: row;
    color: black !important;

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: black;

      span {
        font-weight: bold;
        font-size: 0.8rem;
        padding-left: 0.3rem;
      }

      .logo {
        max-height: 56px;
        max-width: 225px;
        padding: 8px 4px 8px 12px;
      }
    }

    .logoutbutton {
      position: absolute;
      right: 0;
      margin: 1rem 1rem 1rem 0;
    }
  }
}
