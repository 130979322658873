@import 'variables.scss';

.wrapper {
  text-align: center;
}

.logo {
  margin-top: 1rem;
  width: 300px;
  margin-bottom: 1rem;
}

.container {
  padding: 2rem;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 2rem;
  border: 1px solid $primary-blue;
  border-radius: 8px;
  background-color: $background-grey;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form {
  display: flex;
  flex-direction: column;
}

a {
  color: $primary-red !important;
  font-weight: 550;
  text-decoration: underline !important;
}

.formField {
  margin-bottom: 0;
}

.checkboxField {
  margin-top: 2rem; /* Adjust the value as needed */
}

.checkbox {
  color: $primary-blue !important;
}

.submitButton {
  margin-top: 1.5rem !important;
  background-color: $primary-blue !important;
  color: white !important;
  max-width: 10rem;

  &:hover {
    background-color: lighten($primary-blue, 20%);
  }

  &:disabled {
    background-color: #e5e5e5 !important;
    color: #a9a9a9 !important;
  }
}
