@import '_variables.scss';
​
// prettier-ignore
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, input, button, * {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}
​
/* HTML5 display-role reset for older browsers */
// prettier-ignore
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
​ html,
body {
  font-size: 16px;
  line-height: 1.5;
  font-family: map-get($fonts, poppins);
  height: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
}
​ ol,
ul {
  list-style: none;
}
​ blockquote,
q {
  quotes: none;
}
​ blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
​ table {
  border-collapse: collapse;
  border-spacing: 0;
}
​
// prettier-ignore
h1, h2, h3, h4, h5, h6, .MuiTableCell-root {
  font-family: map-get($fonts, poppins);
}
​ a,
a:hover,
a:visited,
a:active {
  color: var(--shade-darkest);
}
​ button {
  cursor: pointer;
}

.MuiTableCell-root,
.MuiTab-wrapper {
  font-family: map-get($fonts, poppins) !important;
}
