.container {
  padding: 1rem;

  button {
    margin-left: 1rem;
    text-transform: none !important;
  }

  a {
    text-transform: none !important;
  }

  h2 {
    margin-bottom: 1rem;
  }

  h2,
  p {
    margin-top: 0.5rem;

    strong {
      font-weight: bold;
    }
  }

  h2 {
    color: #da0846;
  }

  strong {
    font-weight: 600;
  }
}

.inputLabel {
  font-size: 16;
  color: rgba(0, 0, 0, 0.54);
}

.formControl {
  div {
    margin: 8px;
    white-space: normal;
  }
}

.formDiv {
  margin-top: 32px;
}

.backdrop {
  z-index: 1201 !important;
  color: '#fff';
}

.backgroundcolor {
  background-color: #f5f5f5;
}

.MuiPaper-root label {
  font-size: 12;
}

.accordionDetails {
  display: grid !important;
  margin-left: 3em;
}

.chip {
  font-size: 8;
  font-weight: bold;
  margin-left: 2em;
  height: 1.7em !important;
}

.secondaryHeading {
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.9375rem;
}

.heading {
  text-align: left;
  font-size: 0.9375rem;
  flex-basis: 50%;
  flex-shrink: 0;
  margin-left: 3rem !important;
}

.root {
  width: 100%;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paper {
  padding: 16px 32px 24px;
  box-shadow: 0px 3px 5px -1px rgb(0, 0, 0/20), 0px 5px 8px 0px rgb(0, 0, 0/ 14),
    0px 1px 14px 0px rgb(0, 0, 0/ 12);
  background-color: #fff;
}

.paragraphHeading {
  color: #da0846;
  font-size: larger;
  margin-bottom: 1.5em;
}

.confirmationMessage {
  border: 1px black solid;
  text-align: center;
}

.h2ConfirmationMessage {
  align-items: center;
}

.errorIcon {
  color: #da0846;
  vertical-align: text-bottom;
}

.successIcon {
  color: green;
  vertical-align: text-bottom;
}

@media (min-width: 768px) {
  .formControl {
    div {
      min-width: 500px;
    }
  }
}

@media (max-width: 768px) {
  .formControl {
    min-width: 100% !important;
  }
}

.selectedTime {
  border: 2px solid #da0846;
}
