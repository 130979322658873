.container {
  padding: 1rem;

  .primaryButton {
    margin-left: 1rem;
    margin-top: 2rem;
    background-color: #da0846;
    color: white;

    &:hover {
      background-color: lighten(#da0846, 20%);
    }

    &:disabled {
      background-color: #e5e5e5;
      color: #a9a9a9;
    }
  }

  .multilinetextinputField {
    margin-top: 1rem;
    padding-top: 2.5rem;
  }
}
