.container {
  padding: 1rem;

  button {
    margin-left: 1rem;
    margin-top: 0.25rem;
    text-transform: uppercase !important;
    background-color: #636c93;
    color: #f3f4f8;
  }

  a {
    text-transform: uppercase !important;
  }

  h2 {
    margin-bottom: 1rem;
  }

  h2,
  p {
    margin-top: 0.5rem;

    strong {
      font-weight: bold;
    }
  }

  h2 {
    color: #636c93;
  }

  strong {
    font-weight: 600;
  }

  li {
    height: 100%;
  }

  .accordion {
    margin-top: 1rem;
    max-width: 50rem;
  }

  .accordionSummary {
    background-color: rgb(232, 244, 253);
    color: rgb(13, 60, 97);
    border-radius: 4px;
    padding: 0.25rem 1rem;
    cursor: pointer;
    max-height: 2rem;
  }
}

.classList {
  background-color: #fff;
}

.backdrop {
  z-index: 1201 !important;
  color: white;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    margin-bottom: 0.25rem;
  }

  .formControl {
    margin-bottom: 1rem;
  }

  button {
    background-color: #636c93;
    color: white;
    margin-right: 1rem;
    margin-top: 1rem;
    &:disabled {
      background-color: #636c93;
      color: white;
      opacity: 0.5;
    }
  }
}

.paper {
  padding: 16px 32px 24px;
  box-shadow: 0px 3px 5px -1px rgb(0, 0, 0/20), 0px 5px 8px 0px rgb(0, 0, 0/ 14),
    0px 1px 14px 0px rgb(0, 0, 0/ 12);
  background-color: #fff;
  max-width: 700px;
  line-height: 2;
}

.paragraphHeading {
  color: #636c93;
  font-size: larger;
  margin-bottom: 1em;
}

.detailsLable {
  font-weight: bold;
}

.bookNewTutorialLabel {
  vertical-align: top;
  margin-right: 2.5em;
}

.bookNewTutorialButton {
  margin-left: 0 !important;
  color: white !important;
  font-size: 1.5rem !important;
  background-color: #636c93 !important;
  text-transform: capitalize !important;
}

.tabs {
  button {
    background-color: white !important;
    color: #636c93;
    &:hover {
      background-color: white !important;
      color: #636c93;
    }
  }
}

.confirmCancelButton {
  &:hover {
    color: white;
  }
}

.copyToClipboardLink {
  margin-top: 1rem;
  font-weight: 500;
  .linkText {
    padding-left: 1rem;
    display: block;
  }
}

@media (max-width: 900px) {
  .classList {
    min-width: 100% !important;
    width: 100% !important;
  }
}
