.backdrop {
  z-index: 1201 !important;
  color: '#fff';
}
.container {
  h1 {
    margin-bottom: 1rem;
  }

  h2 {
    display: flex;
    align-content: center;
  }

  .section {
    margin-bottom: 2rem;
  }

  .lectureCard {
    margin-bottom: 1rem;
    padding: 2rem;
    background-color: lightyellow;
  }

  .buttonLike {
    cursor: pointer;
    display: inline-block;
    margin-left: 3rem;
  }

  .floatingButton {
    margin: 0;
    top: auto;
    right: 2rem;
    bottom: 20px;
    left: auto;
    position: fixed;
  }

  .floatingButtonTwo {
    margin: 0;
    top: auto;
    right: 6rem;
    bottom: 20px;
    left: auto;
    position: fixed;
  }

  .accordionSummary {
    background-color: #f5f5f5;
    border-radius: 4px;
    padding: 0.25rem 1rem;
    cursor: pointer;
  }
}
