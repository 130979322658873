.smaller {
  font-size: 0.9rem;
}
.gradebookSections {
  margin-top: 0.75rem;
  padding-left: 0.5rem;

  &:first-of-type {
    margin-top: 1.25rem;
  }

  ul {
    li:nth-child(1) {
      font-weight: 600;
    }

    ul {
      margin-left: 1rem;

      li {
        font-weight: normal !important;

        svg {
          margin-left: 0.25rem;
          font-size: 1.25rem;
          padding-top: 5px;
        }

        svg.checkBox {
          color: green;
        }

        svg.unCheckBox {
          color: red;
        }

        svg.partialCheckBox {
          color: orange;
        }
      }
    }
  }
}
