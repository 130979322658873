@use "sass:math";

.container {
  thead {
    tr {
      background-color: #e3e3e3;
    }
  }

  tbody {
    tr {
      &:nth-child(odd) {
        background-color: #f5f5f5;
      }
    }
  }

  .backdrop {
    z-index: 99999;
  }

  .accordion {
    margin-top: 1rem;
    margin-left: 1rem;
  }

  .accordionSummary {
    background-color: #f5f5f5;
    border-radius: 4px;
    padding: 0.25rem 1rem;
    cursor: pointer;

    h2 a {
      font-size: small;
    }
  }

  .accordionDetails {
    h3 {
      font-weight: bold;
      margin-top: 1rem;
      margin-bottom: 0.25rem;
    }
  }

  button {
    margin-left: 1rem;
    padding: 0.25rem;
  }

  input,
  textarea,
  select {
    border: black 1px solid;
  }
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .paper {
    padding: 16px 32px 24px;
    box-shadow: 0px 3px 5px -1px rgb(0, 0, math.div(0, 20)),
      0px 5px 8px 0px rgb(0, 0, math.div(0, 14)), 0px 1px 14px 0px rgb(0, 0, math.div(0, 12));
    background-color: #fff;
    //max-width: 1000px;
    line-height: 2;
    max-height: 90%;
    overflow-y: scroll;
  }

  span {
    margin-bottom: 0.25rem;
  }

  .formControl {
    margin-bottom: 1rem;
  }

  thead {
    tr {
      background-color: #e3e3e3;
    }
  }

  tbody {
    tr {
      &:nth-child(odd) {
        background-color: #f5f5f5;
      }
    }
  }

  tfoot tr {
    background-color: #e3e3e3;
  }

  button {
    background-color: #636c93;
    color: white;
    margin-right: 1rem;
    margin-top: 1rem;

    &:disabled {
      background-color: #636c93;
      color: white;
      opacity: 0.5;
    }
  }
}

.teachingskillssettings {
  display: flex;
  flex-direction: column;

  .inputgroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    label {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-width: 25rem;

      input {
        margin-top: 5px;
        padding: 5px;
        width: 100%;
        box-sizing: border-box;
      }
    }

    &:first-of-type {
      input {
        max-width: 10rem;
      }
    }
  }
}
