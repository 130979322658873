@import 'variables';
// NOTE - overrides to the calendar must be made in the BookingSystemCalendarOverrides.css file

.container {
  background-color: $thinkific-grey;
  .backdrop {
    z-index: 999999;
    background-color: $primary-blue-matrix-lighter;
  }

  .chip {
    border-radius: 7px;
    font-size: 0.8rem;
    cursor: inherit;

    span {
      padding: 0 0.15rem;
    }
  }

  .bolder {
    font-weight: 500;
  }

  .timezoneSection {
    padding-left: 2rem;
    input {
      background-color: white;
      color: black;
    }
  }

  .manageBookingSection {
    width: 100%;
    padding-bottom: 2rem;

    .accordion {
      margin-left: 2rem;
      margin-bottom: 2rem;
      width: 90%;
      background-color: $primary-blue;
      color: white;

      .accordionSummary {
        font-size: 1.25rem;

        .manageChip {
          background-color: $primary-red;
          margin-top: 0.25rem;
          margin-left: 0.5rem;
          color: white;
          font-size: 1.1rem;
          font-weight: 680;
        }

        svg {
          color: white;
          height: 2rem;
          width: 2rem;
        }
      }

      .accordionDetails {
        width: 100%;
        background-color: white;
        color: black;
        padding: 0;
        > div {
          background-color: white;
          width: 100%;
        }

        button {
          margin-left: 0.5rem;
          margin-top: 0.25rem;
        }
      }
    }
  }

  .calendarContainer {
    background-color: white;
    button {
      background-color: #0d3c61;
      color: white;
      text-transform: uppercase !important;
    }
  }

  &.mobile {
    .accordion {
      margin-left: 0.2rem;
      margin-bottom: 0;
      width: 98%;
      .accordionDetails {
        padding: 0;
      }
    }
    .timezoneSection {
      padding-left: 0.5rem;
    }
  }
}

.alert {
  margin: 1rem;
  a {
    color: $primary-blue !important;
  }
}

// booking modal

.modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .paper {
    padding: 0.5rem;
    box-shadow: 0px 3px 5px -1px rgb(0, 0, 0/20), 0px 5px 8px 0px rgb(0, 0, 0/ 14),
      0px 1px 14px 0px rgb(0, 0, 0/ 12);
    background-color: #fff;
    max-width: 700px;
    width: 100%;
    line-height: 2;
    position: relative;

    h1 {
      color: $secondary-dark-blue;
      font-size: 1.2rem;
      font-weight: 500;
      width: 100%;
    }

    span {
      margin-bottom: 0.25rem;
    }

    ul {
      list-style-type: disc;
      padding: 0;
      margin: 0;

      li {
        margin-left: 1rem;
        font-weight: 500;
      }
    }

    div.blockedunit {
      padding-bottom: 0.5rem;
      strong {
        font-weight: 800;
      }
      .disabledcheckbox {
        top: 0.15rem;
      }
      ul {
        li {
          list-style-type: none;
          font-weight: normal;
          height: 1.3rem;
        }
      }
    }

    fieldset {
      margin-top: 0.25rem;
    }

    button {
      background-color: $primary-blue;
      color: white;
      margin-right: 0.5rem;
      margin-top: 0.15rem;
      text-transform: uppercase !important;

      &.joinLiveButton {
        background-color: $primary-red;
        color: white;
        &:hover {
          background-color: $primary-red-matrix-light;
          color: white;
        }
      }

      &:disabled {
        background-color: $primary-blue-matrix-light;
        color: white;
      }
    }

    .closeButton {
      position: absolute;
      right: 0;
      top: 0rem;
      height: 2rem;
      width: 2rem;

      svg {
        top: 0.15rem;
        position: relative;
      }
    }
  }
}
