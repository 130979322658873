.backdrop {
  z-index: 99999 !important;
  color: white;
}

.staffListItem {
  color: red;
}

.openInNewIcon {
  position: relative;
  top: 0.5rem;
}
