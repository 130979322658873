.root {
  display: block;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;

  h1 {
    font-size: 1.5rem;
  }
  h2 {
    font-size: 1.3rem;
  }
  h3 {
    font-weight: bold;
  }

  a {
    color: #636c93;
    font-weight: 500;
    text-decoration: none;
  }
}
