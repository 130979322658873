@import 'variables';

.container {
  .pagination {
    display: inline-flex;
  }

  button {
    white-space: nowrap;
    background-color: $primary-blue !important;

    &:hover {
      background-color: $primary-blue-matrix-light !important;
    }
  }

  .joinLiveButton {
    background-color: $primary-red !important;
    color: white;
    &:hover {
      background-color: $primary-red-matrix-light !important;
    }
  }

  span strong {
    font-weight: 600;
  }

  .copyToClipboardLink {
    text-align: right;
    margin-top: 0.5rem;
    cursor: pointer;
  }

  @media (max-width: 650px) {
    .secondaryActionButtons {
      position: relative !important;
      top: 2rem;
    }
    .copyToClipboardLink {
      text-align: left;
    }

    div.box,
    div.box div {
      margin-left: 0;
      padding-left: 0;
    }
  }
}
