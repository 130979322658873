.container {
  p {
    margin-top: 1rem;
  }

  table {
    margin-top: 1.5rem;
  }

  .formField {
    margin-top: 1rem;
  }

  .stickyTable {
    max-height: 800px;
  }

  .openInNewIcon {
    position: relative;
    top: 0.5rem;
  }
}
