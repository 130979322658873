.container {
  .resultArea {
    padding-top: 1rem;
  }

  .feedbackIcon,
  .openInNewIcon {
    position: relative;
    top: 0.5rem;
  }

  .completeIcon {
    position: relative;
    top: 0.5rem;
    margin-left: 1rem;
  }

  span.summary {
    font-weight: bold;
  }
}

.modal {
  .bottom {
    font-size: small;
    h2 {
      font-weight: bold;
    }

    max-height: 180px;
    overflow: auto;
  }
}
