.container {
  padding: 1rem;

  .moduleLinks {
    margin-bottom: 2rem;
    span {
      display: inline;
    }
  }

  div.moduleDiv {
    margin-bottom: 4rem;
  }

  .buttonLink {
    background-color: rgb(218, 8, 70);
    color: white;
    border-radius: 5px;
    padding: 0.125rem 1rem 0.125rem 1rem;
    white-space: nowrap;
  }

  .joinDiv {
    margin-top: 1rem;
  }

  button {
    margin-left: 1rem;
  }

  h2 {
    margin-bottom: 1rem;
  }

  h2,
  p {
    margin-top: 0.5rem;

    strong {
      font-weight: bold;
    }
  }

  h2 {
    color: #da0846;
  }

  .rowHeaderCell {
    * {
      color: #da0846;
      font-weight: bold;
    }
  }

  strong {
    font-weight: 600;
  }

  .row {
    &:nth-child(odd) {
      background-color: rgb(249, 249, 249);

      .noWrapCell {
        white-space: nowrap;
      }
    }
  }
}
