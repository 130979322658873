.container {
  .floatingButton {
    margin: 0;
    top: auto;
    right: 2rem;
    bottom: 20px;
    left: auto;
    position: fixed;
  }

  .buttonLike {
    cursor: pointer;
    display: inline-block;
    margin-left: 3rem;
  }

  .floatingButtonTwo {
    margin: 0;
    top: auto;
    right: 6rem;
    bottom: 20px;
    left: auto;
    position: fixed;
  }

  .section {
    margin-bottom: 2rem;
  }

  .lectureCard {
    margin-bottom: 1rem;
    padding: 2rem;
    background-color: lightyellow;
  }
}
