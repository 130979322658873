@use "sass:math";

.container {
  padding: 1rem;

  button {
    margin-left: 1rem;
    text-transform: uppercase;
    background-color: #636c93;
    &:hover {
      background-color: #636c93;
    }
  }

  .textButton {
    margin: 0;
    padding: 0;
    text-decoration: underline;
    font-weight: bold;
  }

  a {
    text-transform: none !important;
  }

  h2 {
    margin-bottom: 1rem;
  }

  h2,
  p {
    margin-top: 0.5rem;

    strong {
      font-weight: bold;
    }
  }

  h2 {
    color: #636c93;
  }

  .highlight {
    color: #636c93;
    font-size: 1.25em;
    font-weight: bold;
  }

  strong {
    font-weight: 600;
  }
}

.inputLabel {
  font-size: 16;
  color: rgba(0, 0, 0, 0.54);
}

.formControl {
  div {
    margin: 8px;
    white-space: normal;
  }
}

.formDiv {
  margin-top: 32px;
}

.backdrop {
  z-index: 1201 !important;
  color: #fff;
}

.backgroundcolor {
  background-color: #f5f5f5;
}

.MuiPaper-root label {
  font-size: 12px;
}

.accordionDetails {
  display: grid !important;
  margin-left: 3em;
}

.chip {
  font-size: 8px;
  font-weight: bold;
  margin-left: 2em;
  height: 1.7em !important;
  background-color: #636c93;
}

.secondaryHeading {
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.9375rem;
}

.heading {
  text-align: left;
  font-size: 0.9375rem;
  flex-basis: 50%;
  flex-shrink: 0;
  margin-left: 3rem !important;
}

.popover {
  padding: 1rem;
}

button.narrowButton {
  margin: 0rem;
  padding: 0;
}

.root {
  width: 100%;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    margin-bottom: 0.5rem;
  }

  button {
    background-color: #636c93;
    color: white;
  }

  .repeatBookings {
    padding: 0.5rem;
    background: #e8f4fd;
    border: rgba(0, 138, 255, 0.61) 1px solid;
    max-height: 20rem;
    max-width: 50rem;
    overflow-y: scroll;

    svg:last-of-type {
      margin-left: 0.5rem;
    }

    svg {
      float: right;
      color: #636c93;
    }
  }

  .button {
    margin-left: 1rem;
    margin-top: 0.5rem;
    text-transform: none !important;
    background-color: #636c93;
    &:first-of-type {
      margin-left: 0;
    }
    &:hover {
      background-color: #636c93;
    }
  }

  .select {
    margin-bottom: 0.5rem;
    > div {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }

  .checkboxRow {
    width: 100%;
  }
}

.paper {
  padding: 16px 32px 24px;
  box-shadow: 0px 3px 5px -1px rgb(0, 0, math.div(0, 20)),
    0px 5px 8px 0px rgb(0, 0, math.div(0, 14)), 0px 1px 14px 0px rgb(0, 0, math.div(0, 12));
  background-color: #fff;
  max-width: 95%;
  max-height: 95%;
  overflow-y: auto;
  overflow-x: auto;
}

.paragraphHeading {
  color: #636c93;
  font-size: larger;
  margin-bottom: 1.5em;
}

.confirmationMessage {
  border: 1px black solid;
  text-align: center;
}

.h2ConfirmationMessage {
  align-items: center;
}

.errorIcon {
  color: #da0846;
  vertical-align: text-bottom;
}

.successIcon {
  color: green;
  vertical-align: text-bottom;
}

.earlierDatesButton {
  margin-left: 3rem !important;
  float: left;
  margin-bottom: 1rem !important;
}

.laterDatesButton {
  margin-right: 3rem !important;
  float: right;
  margin-bottom: 1rem !important;
}

@media (min-width: 768px) {
  .formControl {
    div {
      min-width: 500px;
    }
  }
}

@media (max-width: 768px) {
  .formControl {
    min-width: 100% !important;
  }
}

.selectedTime {
  border: 2px solid #636c93;
}
