.rbc-event {
}

.rbc-event:hover {
  transform: scale(1.1);
  transition: transform 0.2s;
  border: 2px solid #fff;
  margin: -2px;
}

.rbc-event .rbc-event-content {
  white-space: normal;
}

.rbc-time-view .rbc-event .rbc-event-label {
  display: none;
}

/*.rbc-time-view rbc-timeslot-group{*/

/*}*/

.rbc-time-view .rbc-event {
  /*height: fit-content !important;*/
}

.rbc-month-row {
  /*Allows the month-day cell to grow to fit content*/
  display: inline-table !important;
  flex: 0 0 0 !important;
  min-height: 50px !important;
}

/*Hide view (month, day) selection buttons*/
/*.rbc-toolbar .rbc-btn-group:last-of-type {*/
/*  !*display: none;*!*/
/*}*/

/*.rbc-toolbar{*/
/*  flex-direction: column;*/
/*}*/

.rbc-toolbar {
  position: sticky;
  top: 0;
  z-index: 1000;
}

.rbc-toolbar button {
  background-color: #636c93 !important;
  color: white;
}

.rbc-toolbar button:hover {
  background-color: #858cad !important;
  color: white;
}

.rbc-toolbar button:focus {
  background-color: #636c93 !important;
  color: white;
}

.rbc-toolbar .react-datepicker button {
  background-color: #f0f0f0 !important;
  margin: 0.25rem 0;
}

.react-datepicker-popper {
  z-index: 99 !important;
}

.react-datepicker-popper h2 {
}

.react-datepicker-popper button {
  border-color: #f0f0f0 !important;
}

.react-datepicker-popper button span {
  display: inherit;
}

.react-datepicker__tab-loop {
  display: inline-block;
}
