.container {
  height: 100%;

  .fullheight {
    height: 100%;
  }

  span.highlight {
    font-size: 1.5rem;
  }

  #hiddenField {
    display: none;
  }

  p {
    padding: 4rem;
  }

  .uploadButton input {
    display: none;
  }

  div.manualUploadContainer {
    button {
      margin: 0.5rem;
    }
  }
}
