//Style Guide colors

//primary colors
$primary-red: #bc0f45;
$primary-blue: #636c93;

// shades of primary colors
$primary-red-matrix-light: #d05475;
$primary-red-matrix-lighter: #e59fb2;
$primary-blue-matrix-light: #858cad;
$primary-blue-matrix-lighter: #b6bace;

// secondary colors
$secondary-dark-blue: #393e54;
$secondary-highlight-blue: #329ec4;
$secondary-turquoise: #a8dcd8;
$secondary-pink: #dfc5ce;
$secondary-light-blue: #a9e1f8;
$secondary-light-lilac: #f3f4f8;

// shades of secondary colors
$secondary-highlight-blue-matrix-light: #5db5d5;
$secondary-highlight-blue-matrix-lighter: #8dcbe2;
$secondary-dark-blue-matrix-light: #636c92;
$secondary-dark-blue-matrix-lighter: #9298b5;

//other
$hero-blue: #000054;

$thinkific-grey: #f2f3f5;

// BE Core colors
$background-grey: #f3f4f8;
