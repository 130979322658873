.container {
  thead {
    background-color: #e5e5e5;
  }

  .thead {
    background-color: #e5e5e5;
    td {
      font-weight: 500;
    }
  }

  .theadNarrow {
    background-color: #e5e5e5;
    td {
      padding: 0;
    }
  }

  strong {
    font-weight: bold;
  }

  svg,
  h1,
  h2 {
    color: #da0846;
  }

  tbody svg {
    position: relative;
    top: 0.5rem;
    color: forestgreen;
  }

  h2 {
    margin-top: 2rem;
  }

  .listColumn svg {
    color: black;
    font-size: 1rem;
  }

  .primaryButton {
    margin-left: 2rem;
    background-color: #da0846;
    color: white;

    &:hover {
      background-color: lighten(#da0846, 20%);
    }
  }

  .secondaryButton {
    margin-left: 2rem;
    background-color: #ffffff;
    border-color: #da0846;
    border-width: 1px;
    border-style: solid;
    color: #da0846;
  }

  .buttonGroup {
    margin-top: 2rem;
  }

  .lastCell {
    border-bottom: none;
    svg {
      color: forestgreen;
      font-size: 2rem;
    }
  }

  .inputField {
    margin-top: 1rem;
  }

  .multilinetextinputField {
    margin-top: 1rem;
    padding-top: 2.5rem;
  }

  .alert {
    margin-top: 2rem;
  }
}
